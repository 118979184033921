import Choices from 'choices.js';

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1),
                );
                break;
            }
        }
    }
    return cookieValue;
}

export function debounce(func, timeout = 300) {
    /*
      // Example usage:
      const setFilters = debounce((e) => {
        table.setFilter(e.target.name, "=", e.target.value);
      }, 300);
      input.addEventListener("change", function (e) {
        setFilters(e);
      });
  */
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function toggleAreaExpanded(selector) {
    let element;
    if (typeof selector === 'string') {
        element = document.querySelector(selector);
    } else if (typeof selector === 'object') {
        element = selector;
    }
    !(element.getAttribute('aria-expanded') == 'true')
        ? element.setAttribute('aria-expanded', 'true')
        : element.setAttribute('aria-expanded', 'false');
}

export function handleMobileMenu() {
    const menu_btn = document.getElementById('mobile-menu-btn');
    const menu = document.getElementById('main-nav');

    menu_btn.addEventListener('click', (e) => {
        e.preventDefault();
        toggleAreaExpanded(menu_btn);
        menu.classList.toggle('opened');
    });

    hideOnClickOutside(menu, menu_btn);
}

const isVisible = (elem) =>
    !!elem &&
    !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
const isActive = (element, parent) => {
    return (
        element.classList.contains('opened') ||
        element.classList.contains('active') ||
        parent.classList.contains('opened') ||
        parent.classList.contains('active')
    );
};

export function hideOnClickOutside(element, parent) {
    const outsideClickListener = (event) => {
        if (
            !parent.contains(event.target) &&
            !element.contains(event.target) &&
            isVisible(element)
        ) {
            if (
                isActive(element, parent) &&
                parent.querySelector('[aria-expanded]')
            ) {
                toggleAreaExpanded(parent.querySelector('[aria-expanded]'));
            }
            if (
                isActive(element, parent) &&
                parent.hasAttribute('aria-expanded')
            ) {
                toggleAreaExpanded(parent);
            }
            element.classList.remove('opened');
            element.classList.remove('active');
            parent.classList.remove('opened');
            parent.classList.remove('open');
            parent.classList.remove('active');
        }
    };
    document.addEventListener('click', outsideClickListener);
}

export class SelectInsteadTabs {
    constructor(selector) {
        if (typeof selector === 'string') {
            this.original_panel = document.querySelector(selector);
        } else if (typeof selector === 'object') {
            this.original_panel = selector;
        }
        this._makeControl();
    }

    _makeControl() {
        const select_wrapper = document.createElement('div');
        select_wrapper.classList.add('select-wrapper');
        const main_controller_container = document.createElement('div');
        const collapse_btn = document.createElement('button');
        const text_content = document.createElement('span');
        const caret_icon = document.createElement('span');
        caret_icon.classList.add('icon-arrow_down');
        collapse_btn.appendChild(caret_icon);
        main_controller_container.classList.add('select-tab-controller');
        main_controller_container.appendChild(text_content);
        main_controller_container.querySelector('span').innerText =
            this._getActiveTabName();
        main_controller_container.appendChild(collapse_btn);
        select_wrapper.appendChild(main_controller_container);
        const new_list = this._makeList();
        select_wrapper.appendChild(new_list);
        this.original_panel.parentNode.insertBefore(
            select_wrapper,
            this.original_panel.parentNode.firstChild,
        );

        collapse_btn.addEventListener('click', (e) => {
            e.preventDefault();
            main_controller_container.classList.toggle('open');
        });

        new_list.querySelectorAll('li').forEach((elem) => {
            elem.addEventListener('click', (e) => {
                const original_object_to_active = [
                    ...this._getOriginalActiveObjects(),
                ].filter((obj) => {
                    return elem.innerText == obj.innerText;
                })[0];
                main_controller_container.querySelector('span').innerText =
                    elem.innerText;
                this._clearActiveClasses(new_list);
                elem.classList.add('active');
                elem.querySelector('button').setAttribute(
                    'aria-selected',
                    'true',
                );
                original_object_to_active.dispatchEvent(new Event('click'));
                collapse_btn.dispatchEvent(new Event('click'));
            });
        });

        hideOnClickOutside(
            main_controller_container,
            main_controller_container,
        );
    }

    _clearActiveClasses(ul) {
        ul.querySelectorAll('li').forEach((li) => {
            li.querySelector('button').setAttribute('aria-selected', 'false');
            li.classList.remove('active');
        });
    }

    _getActiveTabName() {
        return this.original_panel.querySelector('.active').textContent.trim();
    }

    _getOriginalActiveObjects(original_active_objects = 'button') {
        return this.original_panel.querySelectorAll(original_active_objects);
    }

    _makeList() {
        const original_nav_links = this._getOriginalActiveObjects();
        const new_list = document.createElement('ul');
        new_list.classList.add('mobile_tab_list', 'nav');
        new_list.setAttribute('role', 'tablist');
        const elems_of_list = [];
        original_nav_links.forEach((el) => {
            if (el.classList.contains('active')) {
                elems_of_list.push(
                    `<li class="active"><button role="tab">${el.innerText}</button></li>`,
                );
            } else {
                elems_of_list.push(
                    `<li><button role="tab">${el.innerText}</button></li>`,
                );
            }
        });
        new_list.innerHTML = elems_of_list.join('');
        return new_list;
    }
}

export async function sendDeleteRequest(url) {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': getCookie('csrftoken'),
        },
    });
}
export async function sendPostRequest(url, data) {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': getCookie('csrftoken'),
        },
    });
}

export function textInterpolation(template, ...expressions) {
    return template.reduce((accumulator, part, i) => {
        return accumulator + expressions[i - 1] + part;
    });
}

const ACTIONS = ['INC', 'DEC'];

function operateOnInput(target, action) {
    const input_id = target
        .closest('.spinner-container')
        .getAttribute('data-input');
    const input = document.getElementById(input_id);
    const min = parseFloat(input.getAttribute('min')) || null;
    const max = parseFloat(input.getAttribute('max')) || null;
    if (action == ACTIONS[0]) {
        input.value < max || max == null ? input.stepUp() : '';
    } else {
        input.value > min || min == null ? input.stepDown() : '';
    }
    input.dispatchEvent(new Event('change'));
}

export function initializeNumberSpinner(parent_element = document) {
    /* CUSTOM SPINERS FOR INPUT TYPE NUMBER */
    const spinner_template = `
    <div class="spinner-container">
        <div class="up"><span class="icon-arrow_up"></span></div>
        <div class="down"><span class="icon-arrow_down"></span></div>
    </div>
    `;
    const number_inputs = parent_element.querySelectorAll(
        "fieldset > input[type='number']",
    );
    number_inputs.forEach((el) => {
        const parent = el.closest('fieldset');
        if (
            !parent.querySelector('input').disabled &&
            !parent.querySelector('.spinner-container')
        ) {
            el.insertAdjacentHTML('afterend', spinner_template);
            parent
                .querySelector('.spinner-container')
                .setAttribute('data-input', el.id);
        }
    });

    parent_element.querySelectorAll('.spinner-container .up').forEach((el) => {
        el.addEventListener('click', function (e) {
            operateOnInput(e.target, ACTIONS[0]);
        });
    });

    parent_element
        .querySelectorAll('.spinner-container .down')
        .forEach((el) => {
            el.addEventListener('click', function (e) {
                operateOnInput(e.target, ACTIONS[1]);
            });
        });
}

export function initializeSelect(rootElement) {
    let element;
    if (typeof rootElement === 'string') {
        element = document.querySelector(selector);
    } else if (typeof rootElement === 'object') {
        element = rootElement;
    }
    const choice = new Choices(element, { shouldSort: false });
    element.addEventListener('clean', (e) => {
        choice.setChoiceByValue('');
    });
}

export default {
    getCookie,
    debounce,
    toggleAreaExpanded,
    handleMobileMenu,
    hideOnClickOutside,
    SelectInsteadTabs,
    sendDeleteRequest,
    sendPostRequest,
    textInterpolation,
    initializeNumberSpinner,
    initializeSelect,
};
