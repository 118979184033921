import flatpickr from 'flatpickr';

const pickers = {};

export function initializeDateRangePicker(element) {
    pickers[element.id] = flatpickr(element, {
        mode: 'range',
        onClose: function (selectedDates, dateStr, instance) {
            if (selectedDates.length === 1) {
                instance.setDate([selectedDates[0], selectedDates[0]], true);
            }
        },
        onChange: function (selectedDates, dateStr, instance) {
            if (
                selectedDates[1] &&
                new Date(selectedDates[0]).setHours(0, 0, 0, 0) !==
                    new Date(selectedDates[1]).setHours(0, 0, 0, 0)
            ) {
                dateStr = dateStr.replace('to', 'do:');
                instance.element.value = `od: ${dateStr}`;
            } else {
                instance.element.value = dateStr;
            }
        },
    });
}

export function initializeDateTimeFields(element) {
    element.querySelectorAll('.daterangeinput').forEach((element) => {
        initializeDateRangePicker(element);
    });
}

document.addEventListener('DOMContentLoaded', async function () {
    initializeDateTimeFields(document);
});

export default {
    initializeDateTimeFields,
};
