import { getCookie } from './utils';

const EMPTY_FILE_PLACEHOLDER = gettext(
    'Dodaj załączniki (*jpg *png - max 10MB)',
);

export function initialize(container) {
    const select = container.querySelector('select');

    select
        .querySelectorAll('option[selected]')
        .forEach(async function (option) {
            if (option.value === '') return;
            let file_data = null;
            const response = await fetch(`/api/files/${option.value}/`, {
                headers: {
                    ajax: true,
                },
            });
            file_data = response.data;
            addUploadedFile(
                {
                    file: file_data,
                    name: option.text,
                    id: option.value,
                },
                container,
            );
        });

    if (select.disabled) return;

    const fileInput = container.querySelector('input');
    fileInput.addEventListener('change', function (e) {
        handleFiles(container, this.files, select);
    });

    handleInput(container, select);

    const selectButton = container.querySelector('.fileupload-container');
    selectButton.addEventListener('click', function (e) {
        fileInput.click();
    });
}

export function initializeFileUpload(element) {
    element.querySelectorAll('.fileupload-field').forEach((element) => {
        initialize(element);
    });
}

export function addUploadedFile(fileData, container) {
    const uploadedList = container.querySelector('.fileupload-list');
    const multiple = container.classList.contains('multiple');

    if (!multiple) {
        uploadedList.innerHTML = '';
    }
    uploadedList.insertAdjacentHTML(
        'beforeend',
        `
      <div class="fileupload-container file-margin">
        <div class="fileupload-list-icon-container single-file-icon">
            <span class="icon-attachment"></span>
        </div>
        <div class="fileupload-details">
            <span class="fileupload-selected">${fileData.name}</span>
            <div class="fileupload-delete" data-id="${fileData.id}">
                <span class="icon-delete"></span>
            </div>
        </div>
    </div>
    `,
    );
}

function handleFiles(container, files, select) {
    [...files].forEach((file) => {
        uploadFile(file, container);
    });
    handleInput(container, select);
}

function handleInput(container, select) {
    const pluralFileInfo = function (select) {
        const files_length = select.selectedOptions.length;
        if (files_length == 1) {
            return gettext('Wybrano ') + files_length + gettext(' plik');
        } else if (files_length > 4) {
            return gettext('Wybrano ') + files_length + gettext(' plików');
        } else {
            return gettext('Wybrano ') + files_length + gettext(' pliki');
        }
    };
    container.querySelector('.fileupload-chosen').innerText =
        `${select.selectedOptions.length ? pluralFileInfo(select) : EMPTY_FILE_PLACEHOLDER}`;
}

export async function uploadFile(file, container) {
    const select = container.querySelector('select');

    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(select.dataset.upload, {
        method: 'POST',
        body: formData,
        headers: {
            'X-CSRFToken': getCookie('csrftoken'),
        },
    });
    if (response.status === 200) {
        const option = document.createElement('option');
        const responseData = await response.json();
        option.value = responseData.id;
        option.text = responseData.name;
        option.selected = true;
        select.add(option);
        responseData.file = URL.createObjectURL(file);
        addUploadedFile(responseData, container);
    }
}

document.addEventListener('click', async function (e) {
    if (
        e.target.classList.contains('fileupload-delete') ||
        e.target.classList.contains('icon-delete')
    ) {
        const container = e.target.closest('.fileupload-field');
        const select = container.querySelector('select');
        const form = container.closest('form');
        const option = e.target.classList.contains('icon-delete')
            ? container.querySelector(
                  `select option[value="${e.target.parentElement.dataset.id}"]`,
              )
            : container.querySelector(
                  `select option[value="${e.target.dataset.id}"]`,
              );
        option.selected = false;
        if (form) form.dispatchEvent(new Event('change'));
        e.target.closest('.fileupload-container').remove();
        handleInput(container, select);
    }
});

window.addEventListener('DOMContentLoaded', async function () {
    await initializeFileUpload(document);
});

export default {
    initialize,
    initializeFileUpload,
    uploadFile,
};
