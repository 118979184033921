import Sortable from 'sortablejs';
import { initializeSelect, sendPostRequest } from './utils';
import { initializeNumberSpinner } from './utils';
import modals from './modals';

async function getWasteTypes() {
    const response = await fetch('/api/wastes/waste/types/', {
        headers: {
            ajax: true,
        },
    });
    return await response.json();
}

async function prepareNewWasteForm(wastFormULRS) {
    const response = await fetch(wastFormULRS, {
        headers: {
            ajax: true,
        },
    });
    return await response.text();
}

async function fetchWastes(wastFormULRS) {
    let wasteItem = {
        0: {
            name: gettext('Luzem'),
            key: 'loose',
            wastes: Array(),
        },
        nextPalletIdx: 1,
        nextWasteIdx: 1,
    };
    if (wastFormULRS) {
        const response = await fetch(wastFormULRS, {
            headers: {
                ajax: true,
            },
        });
        const wastData = await response.json();
        wasteItem[0].wastes = wastData.loose_wasts;
        Object.keys(wastData.pallets).forEach(function (key) {
            wasteItem[Number(key) + 1] = wastData.pallets[key];
        });
        Object.keys(wasteItem).forEach(function (key) {
            if (typeof wasteItem[key] == 'object') {
                wasteItem[key]['wastes'].forEach(function (waste) {
                    if (waste.hasOwnProperty('device_data')) {
                        Object.keys(waste['device_data']).forEach(
                            function (waste_data_key) {
                                waste[waste_data_key] =
                                    waste['device_data'][waste_data_key].value;
                            },
                        );
                    }
                });
            }
        });
        wasteItem.nextPalletIdx = wastData.pallets.length + 1;
        wasteItem.nextWasteIdx = wastData.loose_wasts.length;
        return wasteItem;
    } else {
        return wasteItem;
    }
}

function renderWaste(waste, wasteTypes) {
    if (
        typeof waste.waste_type === 'object' &&
        !Array.isArray(waste.waste_type) &&
        waste.waste_type !== null
    ) {
        return `<div class="waste" id="waste_${waste.id}">
            <span class="waste-name"><b>${gettext('ODPAD')}</b> (${waste.id}) - ${waste.waste_type.name}</span>
            <div class="ordering-handle">
                <span class="icon-move"></span>
            </div>
        </div>`;
    } else {
        return `<div class="waste" id="waste_${waste.id}">
            <span class="waste-name"><b>${gettext('ODPAD')}</b> (${waste.id}) - ${wasteTypes.find((x) => x.value === waste.waste_type).label}</span>
            <div class="ordering-handle">
                <span class="icon-move"></span>
            </div>
        </div>`;
    }
}

function renderLoose(palet, wasteTypes, looseElement) {
    let pallet_weight = 0;
    const pallet_header_alert = looseElement.parentElement
        .querySelector('h1')
        .getElementsByClassName('alert')[0];
    palet.wastes.forEach((waste) => {
        if (waste.weight) {
            pallet_weight += parseFloat(waste.weight);
        }
    });
    if (pallet_weight > 25) {
        pallet_header_alert.style.display = 'flex';
    } else {
        pallet_header_alert.style.display = 'none';
    }
    return [
        `<div class="pallet ${pallet_weight > 25 ? 'overweight' : ''}">
            ${
                palet['wastes'].length === 0
                    ? `<div class="wastes empty">${gettext('BRAK ODPADU')}</div>`
                    : `<div class="wastes">
                ${palet['wastes']
                    .map((waste) => {
                        return renderWaste(waste, wasteTypes);
                    })
                    .join('')}
            </div>`
            }
        </div>`,
        pallet_weight,
    ];
}

function renderPallet(palet, paletId, wasteTypes) {
    let pallet_weight = 0;
    palet.wastes.forEach((waste) => {
        if (waste.weight) {
            pallet_weight += parseFloat(waste.weight);
        }
    });
    return `<div class="pallet" id="${paletId}">
            <div class="palletTopBar">
                <div class="palletLabel">${gettext('PALETA')} (${paletId})</div>
                <button class="btn-secondary-action-remove"  data="${paletId}"  ${palet['wastes'].length ? `title="${gettext('Możliwe usunięcie palety tylko gdy jest pusta')}" disabled` : `title="${gettext('Usuń paletę')}"`}>${gettext('Usuń')}</button>
                <div class="arrow-container" data="${paletId}"><span class="icon-arrow_down"></span></div>
            </div>
            <div class="blankLine"></div>
            <div class="palletContent">
            <div class="palleParams">
                <fieldset>
                <label for="${paletId}width">${gettext('Szerokość (cm)')}</label>
                <input id="${paletId}width" type="number" step="0.1" min="1" value="${palet.width}"/>
                </fieldset>
                <fieldset>
                <label for="${paletId}height">${gettext('Wysokość (cm)')}</label>
                <input id="${paletId}height" type="number" step="0.1" min="1" value="${palet.height}"/>
                </fieldset>
                <fieldset>
                <label for="${paletId}depth">${gettext('Głębokość (cm)')}</label>
                <input id="${paletId}depth" type="number" step="0.1" min="1" value="${palet.depth}"/>
                </fieldset>
                <fieldset>
                <label for="${paletId}weight">${gettext('Waga (kg)')}</label>
                <input id="${paletId}weight" type="number" step="0.1" min="1" value="${parseFloat(pallet_weight).toFixed(2)}"/>
                </fieldset>
            </div>
            ${
                palet['wastes'].length === 0
                    ? `<div class="wastes"><div class="emptyPallet">${gettext('BRAK ODPADU')}</div></div>`
                    : `<div class="wastes">
                ${palet['wastes']
                    .map((waste) => {
                        return renderWaste(waste, wasteTypes);
                    })
                    .join('')}
            </div>`
            }
            </div>
        </div>`;
}

async function onMoveAction(evt, wastesItems, wasteTypes, newWasteForm) {
    const previousList = evt.from.parentNode,
        targetList = evt.to.parentNode,
        previousId =
            'loose' === previousList.parentElement.id
                ? `0`
                : previousList.parentElement.id;
    // ADD NEW PALLET
    if (targetList.id === 'pallets') {
        wastesItems[wastesItems['nextPalletIdx'].toString()] = {
            width: 1,
            height: 1,
            depth: 1,
            weight: 1,
            wastes: [wastesItems[previousId].wastes[evt.oldIndex]],
        };
        wastesItems[previousId].wastes.splice(evt.oldIndex, 1);
        wastesItems['nextPalletIdx'] += 1;
        // MOVE BETWEEN PALLETS
    } else {
        const targetId =
            'loose' === targetList.parentElement.id
                ? `0`
                : targetList.parentElement.id;
        if (targetId !== previousId) {
            if (
                targetId &&
                previousId &&
                !isNaN(Number(targetId)) &&
                !isNaN(Number(previousId))
            ) {
                wastesItems[targetId].wastes.splice(
                    evt.newIndex,
                    0,
                    wastesItems[previousId].wastes[evt.oldIndex],
                );
                wastesItems[previousId].wastes.splice(evt.oldIndex, 1);
            }
        }
    }
    reRenderWastes(wastesItems, wasteTypes, newWasteForm);
}

function renderWastes(wastesItems, wasteTypes, newWasteForm) {
    const looseElement = document.getElementById('loose'),
        palletsElement = document.getElementById('pallets'),
        forwardButton = document.getElementById('forwardButton');
    const [looseHTML, pallet_weight] = renderLoose(
        wastesItems['0'],
        wasteTypes,
        looseElement,
    );
    looseElement.innerHTML = looseHTML.trim();
    let wastesCounter = wastesItems['0'].wastes.length;

    Object.keys(wastesItems).forEach(function (key) {
        const palletId = Number(key);
        if (!isNaN(palletId) && palletId > 0) {
            const div = document.createElement('div');
            wastesCounter += wastesItems[key].wastes.length;
            div.innerHTML = renderPallet(
                wastesItems[key],
                key,
                wasteTypes,
            ).trim();
            palletsElement.appendChild(div.firstChild);
        }
    });
    forwardButton.disabled = !(wastesCounter > 0 && pallet_weight <= 25);
    const newPallet = document.createElement('div');
    newPallet.id = 'new_pallet';
    newPallet.classList.add('wastes');
    newPallet.classList.add('empty');
    newPallet.innerText = gettext(
        'PRZECIĄGNIJ I UPUŚĆ BY UTWORZYĆ NOWĄ PALETĘ',
    );
    palletsElement.appendChild(newPallet);

    document.querySelectorAll('.arrow-container').forEach((element) => {
        element.addEventListener('click', async (e) => {
            const span = element.firstChild,
                target = document
                    .getElementById(element.getAttribute('data'))
                    .getElementsByClassName('palletContent')[0],
                targetBlank = document
                    .getElementById(element.getAttribute('data'))
                    .getElementsByClassName('blankLine')[0];

            if (target.style.display === 'none') {
                target.style.display = 'block';
                targetBlank.style.display = 'none';
                span.classList.replace('icon-arrow_right', 'icon-arrow_down');
            } else {
                target.style.display = 'none';
                targetBlank.style.display = 'block';
                span.classList.replace('icon-arrow_down', 'icon-arrow_right');
            }
        });
    });

    document
        .querySelectorAll('.btn-secondary-action-remove')
        .forEach((element) => {
            element.addEventListener('click', (e) => {
                delete wastesItems[element.getAttribute('data')];
                reRenderWastes(wastesItems, wasteTypes, newWasteForm);
            });
        });

    document.querySelectorAll('.wastes').forEach((element) => {
        new Sortable(element, {
            group: 'shared',
            animation: 150,
            delay: 5,
            handle: '.ordering-handle',
            forceFallback: true,
            onEnd: async function (evt) {
                await onMoveAction(evt, wastesItems, wasteTypes, newWasteForm);
            },
        });
        element.addEventListener('click', async (e) => {
            let target = e.target;
            while (!target.classList.contains('waste')) {
                if (target.classList.contains('ordering-handle')) {
                    return;
                }
                target = target.parentElement;
                if (
                    target === null ||
                    target.classList.contains('empty') ||
                    target.classList.contains('ordering-handle')
                )
                    return;
            }
            await editWasteModal(target, newWasteForm, wastesItems, wasteTypes);
        });
    });

    document.querySelectorAll('.pallet').forEach((pallet) => {
        const paletId = pallet.id;
        const widthElement = document.getElementById(`${paletId}width`),
            heightElement = document.getElementById(`${paletId}height`),
            depthElement = document.getElementById(`${paletId}depth`),
            weightElement = document.getElementById(`${paletId}weight`);
        if (weightElement !== null) {
            widthElement.addEventListener('change', async (e) => {
                wastesItems[paletId].width = e.target.valueAsNumber;
            });
            heightElement.addEventListener('change', async (e) => {
                wastesItems[paletId].height = e.target.valueAsNumber;
            });
            depthElement.addEventListener('change', async (e) => {
                wastesItems[paletId].depth = e.target.valueAsNumber;
            });
            weightElement.addEventListener('change', async (e) => {
                wastesItems[paletId].weight = e.target.valueAsNumber;
            });
        }
    });
    initializeNumberSpinner();
}

function reRenderWastes(wastesItems, wasteTypes, newWasteForm) {
    const looseElement = document.getElementById('loose'),
        palletsElement = document.getElementById('pallets');
    looseElement.innerHTML = '';
    palletsElement.innerHTML = '';
    renderWastes(wastesItems, wasteTypes, newWasteForm);
}

export async function addWasteModal(
    event,
    newWasteForm,
    wastesItems,
    waste,
    wasteTypes,
) {
    const div = document.createElement('div');

    const addWasteFunction = async function addWaste(form) {
        const formData = new FormData(form),
            newWaste = {},
            loading_select = formData.get('loading_select');

        for (const pair of formData.entries()) {
            if (pair[0] !== 'search_terms') {
                newWaste[pair[0]] = pair[1];
            }
        }
        delete newWaste['loading_select'];
        newWaste['id'] = wastesItems['nextWasteIdx'].toString();
        newWaste['waste_type'] = {
            id: newWaste['waste_type'],
            name: document
                .getElementById(`id_waste_type`)
                .querySelector(`[value="${newWaste['waste_type']}"]`).text,
        };
        if ('waste_subtype' in newWaste) {
            newWaste['waste_subtype'] = {
                id: newWaste['waste_subtype'],
            };
        }
        if ('liquidation_reason' in newWaste) {
            newWaste['liquidation_reason'] = {
                id: newWaste['liquidation_reason'],
            };
        }
        if ('waste_code' in newWaste) {
            newWaste['waste_code'] = {
                id: newWaste['waste_code'],
            };
        }
        wastesItems['nextWasteIdx'] += 1;
        if (loading_select === '-1') {
            wastesItems[wastesItems['nextPalletIdx'].toString()] = {
                width: 0,
                height: 0,
                depth: 0,
                weight: 0,
                wastes: [newWaste],
            };
            wastesItems['nextPalletIdx'] += 1;
        } else {
            wastesItems[loading_select]['wastes'].push(newWaste);
        }

        document.removeEventListener('formReloaded', initSelects, true);
        reRenderWastes(wastesItems, wasteTypes, newWasteForm);
        modals.closeModal();
    };

    div.innerHTML = (await newWasteForm).trim();
    const loading_select = div.querySelector("select[name='loading_select']"),
        remove_button = div.querySelector("button[id='remove_buton']");
    remove_button.disabled = true;
    Object.keys(wastesItems).forEach(function (key) {
        if (!isNaN(Number(key))) {
            const option = document.createElement('option');
            option.value = key;
            option.text =
                wastesItems[key]['name'] === undefined
                    ? `${gettext('PALETA')} (${key})`
                    : wastesItems[key]['name'];
            loading_select.appendChild(option);
        }
    });
    const option = document.createElement('option');
    option.value = '-1';
    option.text = gettext('Nowa paleta');
    loading_select.appendChild(option);
    const modal = modals.createModal(
        gettext('ODPAD') +
            ` - ${waste === null ? gettext('Brak typu odpadu') : waste.type}`,
        div.innerHTML,
    );
    modals.initializeModalForm(modal, addWasteFunction);
    initSelects();
    document.addEventListener('formReloaded', initSelects, true);
}

export async function editWasteModal(
    target,
    newWasteForm,
    wastesItems,
    wasteTypes,
) {
    const div = document.createElement('div'),
        pallet_element_id = target.parentElement.parentElement.parentElement.id,
        pallet_id = pallet_element_id === 'loose' ? 0 : pallet_element_id,
        waste_element_id = target.id.split('_')[1],
        waste_id = wastesItems[pallet_id].wastes.findIndex(
            (w) => w.id == waste_element_id,
        ),
        waste = wastesItems[pallet_id].wastes[waste_id];

    const updateWasteFunction = async function addWaste(form) {
        const formData = new FormData(form),
            newWaste = {},
            loading_select = formData.get('loading_select');
        for (const pair of formData.entries()) {
            if (pair[0] !== 'search_terms') {
                newWaste[pair[0]] = pair[1];
            }
        }
        newWaste['waste_type'] = {
            id: newWaste['waste_type'],
            name: document
                .getElementById(`id_waste_type`)
                .querySelector(`[value="${newWaste['waste_type']}"]`).text,
        };
        if ('waste_subtype' in newWaste) {
            newWaste['waste_subtype'] = {
                id: newWaste['waste_subtype'],
            };
        }
        if ('liquidation_reason' in newWaste) {
            newWaste['liquidation_reason'] = {
                id: newWaste['liquidation_reason'],
            };
        }
        if ('waste_code' in newWaste) {
            newWaste['waste_code'] = {
                id: newWaste['waste_code'],
            };
        }
        delete newWaste['loading_select'];
        newWaste['id'] = waste.id;
        if (pallet_id == loading_select) {
            wastesItems[pallet_id].wastes[waste_id] = newWaste;
        } else {
            delete wastesItems[pallet_id].wastes[waste_id];
            if (loading_select === '-1') {
                wastesItems[wastesItems['nextPalletIdx'].toString()] = {
                    width: 0,
                    height: 0,
                    depth: 0,
                    weight: 0,
                    wastes: [newWaste],
                };
                wastesItems['nextPalletIdx'] += 1;
            } else {
                wastesItems[loading_select]['wastes'].push(newWaste);
            }
        }

        reRenderWastes(wastesItems, wasteTypes, newWasteForm);
        modals.closeModal();
    };

    div.innerHTML = (await newWasteForm).trim();
    const loading_select = div.querySelector("select[name='loading_select']"),
        remove_button = div.querySelector("button[id='remove_buton']");
    remove_button.setAttribute('pallet-data', pallet_id);
    remove_button.setAttribute('waste-data', waste_id);
    Object.keys(wastesItems).forEach(function (key) {
        if (!isNaN(Number(key))) {
            const option = document.createElement('option');
            option.value = key;
            option.text =
                wastesItems[key]['name'] === undefined
                    ? `${gettext('PALETA')} (${key})`
                    : wastesItems[key]['name'];
            if (key == pallet_id) {
                option.id = 'pallet_set_option';
            }
            loading_select.appendChild(option);
        }
    });
    const option = document.createElement('option');
    option.value = '-1';
    option.text = gettext('Nowa paleta');
    loading_select.appendChild(option);
    const modal = modals.createModal(
        gettext('ODPAD') +
            ` - ${waste === null ? gettext('Brak typu odpadu') : waste.waste_type.name}`,
        div.innerHTML,
    );
    modals.initializeModalForm(modal, updateWasteFunction);

    const pallet_option = document.getElementById('pallet_set_option'),
        waste_type = document
            .getElementById('id_waste_type')
            .querySelector(`[value="${waste['waste_type']['id']}"]`),
        keys_to_miss = { id: null, waste_type: null, waste_subtype: null };
    pallet_option.setAttribute('selected', '');
    waste_type.setAttribute('selected', '');
    try {
        await reloadForm();
    } catch (err) {
        return;
    }
    if ('waste_subtype' in waste && waste['waste_subtype'] !== null) {
        const waste_subtype = document
            .getElementById('id_waste_subtype')
            .querySelector(`[value="${waste['waste_subtype']['id']}"]`);
        waste_subtype.setAttribute('selected', '');
        try {
            await reloadForm();
        } catch (err) {
            return;
        }
    }
    Object.keys(waste).forEach((key) => {
        if (keys_to_miss[key] === undefined) {
            const element = document.getElementById(`id_${key}`);
            if (element !== null) {
                if (element.type === 'checkbox') {
                    element.checked = waste[key];
                } else if (element instanceof HTMLInputElement) {
                    element.value = waste[key];
                } else if (element instanceof HTMLSelectElement) {
                    const sub_element = element.querySelector(
                        `[value="${waste[key]['id']}"]`,
                    );
                    sub_element.setAttribute('selected', '');
                } else if (element.type === undefined) {
                    const sub_element = element.querySelector(
                        `[value="${waste[key]['id']}"]`,
                    );
                    sub_element.checked = true;
                }
            }
        }
    });
    const removeButton = document.getElementById('remove_buton'),
        submitButton = document.getElementById('submit_waste');
    submitButton.innerText = gettext('Zapisz zmiany');
    removeButton.addEventListener('click', async (e) => {
        removeWaste(e, wastesItems, wasteTypes, newWasteForm);
    });
    initSelects();
    document.addEventListener('formReloaded', initSelects, true);
}

async function removeWaste(event, wastesItems, wasteTypes, newWasteForm) {
    event.preventDefault();
    const palletData = event.target.getAttribute('pallet-data'),
        wasteData = event.target.getAttribute('waste-data');
    wastesItems[palletData].wastes.splice(wasteData, 1);
    modals.closeModal();
    reRenderWastes(wastesItems, wasteTypes, newWasteForm);
}

async function submitRequest(event, form, wastesItems, actionForm) {
    event.preventDefault();
    const formData = new FormData(form),
        apiURL = event.originalTarget.getAttribute('action'),
        requestData = [];
    Object.entries(wastesItems).forEach((pallet) => {
        const key = Number(pallet[0]);
        if (!isNaN(key)) {
            if (key !== 0) {
                requestData.push({
                    id: key,
                    wastes: [],
                    width: pallet[1].width,
                    height: pallet[1].height,
                    depth: pallet[1].depth,
                    weight: pallet[1].weight,
                });
            } else {
                requestData.push({
                    id: key,
                    wastes: [],
                });
            }
            pallet[1].wastes.forEach((waste) => {
                Object.keys(waste).forEach((waste_key) => {
                    if (
                        typeof waste[waste_key] === 'object' &&
                        !Array.isArray(waste[waste_key]) &&
                        waste[waste_key] !== null
                    ) {
                        waste[waste_key] = waste[waste_key].id;
                    }
                });
                delete waste['declaration_received'];
                requestData[requestData.length - 1].wastes.push(waste);
            });
        }
    });
    const wasteUrl = event.submitter.dataset.pk
        ? `/api/wastes/waste_collect_requests/automatic/update/${event.submitter.dataset.pk}/`
        : '/api/wastes/waste_collect_requests/create/';
    const response = await sendPostRequest(wasteUrl, requestData);
    if (response.status === 200) {
        if (actionForm == 'save') {
            window.location.href = `/wastes/`;
        } else {
            window.location.href = `/wastes/request/automatic/reception/${(await response.json()).id}/`;
        }
    }
}

export async function initializeWastes(wastFormULRS) {
    const infoAccept = document.getElementById('information_accept');
    infoAccept.addEventListener('change', function (e) {
        if (infoAccept.checked) {
            document.getElementById('checkbox_error').innerText = '';
        }
    });
    const form = document.getElementById('add_wastes');
    const [newWasteForm, wasteTypes, wastesItems] = await Promise.all([
        prepareNewWasteForm(wastFormULRS),
        await getWasteTypes(),
        await fetchWastes(form.dataset.url),
    ]);
    renderWastes(wastesItems, wasteTypes, newWasteForm);
    const addWasteButton = document.getElementById('add_waste'),
        addWasteButtonFake = document.getElementById('add_waste_fake');
    addWasteButton.addEventListener('click', async (event) => {
        await addWasteModal(event, newWasteForm, wastesItems, null, wasteTypes);
    });
    addWasteButtonFake.addEventListener('click', async (event) => {
        await addWasteModal(event, newWasteForm, wastesItems, null, wasteTypes);
    });
    form.addEventListener('submit', async function (e) {
        const actionForm = e.submitter.getAttribute('action');
        if (actionForm === 'save') {
            await submitRequest(e, form, wastesItems, actionForm);
        } else {
            if (infoAccept.checked) {
                await submitRequest(e, form, wastesItems, actionForm);
            } else {
                document.getElementById('checkbox_error').innerText = gettext(
                    'Aby przejść dalej, należy zaakceptować zgodność danych',
                );
                e.preventDefault();
            }
        }
    });
    document.addEventListener('click', function (e) {
        const target = e.target;
        if (
            target.classList.contains('modal') ||
            target.classList.contains('modal__close')
        ) {
            document.removeEventListener('formReloaded', initSelects, true);
        }
    });
}

export default {
    initializeWastes,
};
