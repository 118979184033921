const modalRoot = document.getElementById('modalContainer');

function setInnerHTML(elm, html) {
    elm.innerHTML = html;

    Array.from(elm.querySelectorAll('script')).forEach((oldScriptEl) => {
        const newScriptEl = document.createElement('script');

        Array.from(oldScriptEl.attributes).forEach((attr) => {
            newScriptEl.setAttribute(attr.name, attr.value);
        });

        const scriptText = document.createTextNode(oldScriptEl.innerHTML);
        newScriptEl.appendChild(scriptText);

        oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
    });
}

export function createModal(title, content) {
    const template = `
    <div class="modal show">
        <div class="modal__content">
          <div class="modal__header">
            <span class="modal__close">&times;</span>
            <h2>${title}</h2>
          </div>
          <div class="modal__body">
            ${content}
          </div>
        </div>
    </div>
    `;
    setInnerHTML(modalRoot, template);
    const modal = modalRoot.querySelector('.modal');
    document.body.style.overflowY = 'hidden';
    return modal;
}

export function initializeModalForm(modal, onSubmit) {
    const form = modal.querySelector('form');
    form.addEventListener('submit', async function (e) {
        e.preventDefault();
        if (onSubmit) {
            return onSubmit(form);
        }
        const formData = new FormData(form);
        const response = await fetch(form.action, {
            method: 'post',
            body: formData,
            headers: {
                ajax: true,
            },
        });
        if (response.status === 200) {
            const result = await response.json();
            const createEvent = new CustomEvent('modal-form-saved', {
                detail: result,
            });
            modal.dispatchEvent(createEvent);
            closeModal();
        } else {
            const htmlForm = await response.text();
            modal.querySelector('.modal__body').innerHTML = htmlForm;
            initializeModalForm(modal, null);
        }
    });
}

export function closeModal() {
    const modal = modalRoot.querySelector('.modal');
    if (modal) {
        modal.classList.remove('show');
        modal.classList.add('hide');
        document.body.style.overflowY = '';
        setTimeout(function () {
            modal.remove();
        }, 200);
    }
}

document.addEventListener('click', function (e) {
    const target = e.target;
    if (
        target.classList.contains('modal') ||
        target.classList.contains('modal__close')
    ) {
        modals.closeModal();
    }
});

export default {
    createModal,
    initializeModalForm,
    closeModal,
};
