export default function (cell, formatterParams, onRendered) {
    let url = formatterParams.url(cell);
    const classes = [];
    if (
        'disabledCondition' in formatterParams &&
        formatterParams.disabledCondition(cell)
    ) {
        classes.push('btn-disabled');
        url = 'javascript:void(0)';
    }
    return `<a class="${classes.join(' ')} mr-30" href="${url}">${
        formatterParams.text
    }</a>`;
}
